











































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from 'moment'
import { ProjectResource } from '@/models/projects/ProjectResource'
import { MultipleDocumentsService } from '@/services/multipleDocuments'
import {flexDocSort} from '@/data/flexDocSort'
import Dropdown from '@/components/inputs/Dropdown.vue'
import Modify from './components/Modify.vue'
import RenameDocument from './components/RenameDocument.vue'
import CreateChildDoc from './components/CreateChildDoc.vue'

import DefaultModal from '@/components/modals/Default.vue'
import TextInput from '@/components/inputs/Text.vue'
import Search from '@/components/inputs/Search.vue'
import SwitchInput from '@/components/inputs/Switch.vue'
import DropdownSort from '@/components/inputs/DropdownSort.vue'


@Component({
  components: { DefaultModal, Modify, Dropdown, TextInput, RenameDocument, Search, DropdownSort, SwitchInput, CreateChildDoc }
})
export default class DocumentTemplates extends Vue {
	@Prop() private readonly project!: ProjectResource

	private multipleDocumentsService: MultipleDocumentsService = new MultipleDocumentsService({ project_id: this.project.id })

	public modalCreate: boolean = false
	public isLoading: boolean = false
	public documentsList: any[] = []
	public documentsListTree: any[] = []
	public modifyId: any = null
	public withTemplate: boolean = false
	public selectedTemplate: any = null
	public documentTitle: string = ''
	public projectId: any = null
	public documentToRename: any = null
	public searchQuery: string = ''
	public renameLoading: boolean = false
	public parentDocument: any = null
	public createLoading: boolean = false

	private sort: string = 'name-asc'
  	private sortOptions: SelectItem[] = flexDocSort
	private showArchived: boolean = false

	private created() {
		this.projectId = this.$route.params.project_id
		this.getDocumentsList();
	}

	private formatDate(date: string) {
		const parts = date.split(' ')
		const dateParts = parts[0].split('-')
		const newDate = `${dateParts[1]}-${dateParts[0]}-${dateParts[2]} ${parts[1]}`
		return moment(newDate).format('DD MMM YYYY HH:mm')
	}

	public async createTemplate() {
		let newDoc = null
		try {
			const { data } = await this.multipleDocumentsService.create({
				body: { 
					template: this.withTemplate,
					template_id: this.selectedTemplate,
					title: this.documentTitle
				}
			})
			newDoc = data
		} catch ({ errors, message }) {
			console.log(errors, message);
			
		} finally {
			const docId = newDoc.template_id
			this.$router.push('projects/' + this.projectId + '/flexible-document/' + docId)
		}
	}

	private get mappedTemplates(): SelectItem[] {
		return this.documentsList.map(({ template_id, title }) => ({ label: title, value: template_id }))
	}

	private toTree(data: any, pid: any = null) {
		return data.reduce((r: any, e: any) => {
			if (e.parent_id == pid) {
				const obj = { ...e }
				const children = this.toTree(data, e.template_id)
				if (children.length) obj.children = children
				r.push(obj)
			}
			return r;
		}, [])
	}

	private flattenTreeWithLevels(data: any[], level: number = 0) {
		const flatArr: any[] = []

		data.forEach(item => {
			flatArr.push({...item, children: null, level })
			if (item.children && item.children.length) {
				flatArr.push(...this.flattenTreeWithLevels(item.children, level + 1))
			}
		})
		return flatArr
	}

	private async getDocumentsList() {
		this.isLoading = true
		const { data } = await this.multipleDocumentsService.getDocumentsList('test')
		
		this.documentsListTree = this.toTree(data)
		this.documentsList = this.flattenTreeWithLevels(this.documentsListTree)
		this.isLoading = false
	}

	private async updateDocumentTitle(doc: any) {
		this.renameLoading = true
		const { data } = await this.multipleDocumentsService.updateDocumentTitle(doc.template_id, doc.uuid, doc.title)

		this.documentsList = this.documentsList.map(item => {
			if (doc.uuid === item.uuid) item.title = doc.title
			return item
		})
		this.renameLoading = false
		this.closeRenameDocument()
	}

	public updateModify(id: string) :void {
		this.modifyId = id;
	}

	public renameDocument(doc: any) {
		this.documentToRename = doc
	}

	public closeRenameDocument() {
		this.documentToRename = null
	}

	private detectSelectedItems() {
		const selected = this.documentsList.filter(item => item.selected)
		return selected.length > 0
	}

	private async toggleArchive(doc: any) {

		this.isLoading = true
		const { data } = await this.multipleDocumentsService.toggleArchive({})
		
		this.documentsList = this.documentsList.map(item => {
			if (doc.uuid === item.uuid) item.archived = !item.archived
			return item
		})
		this.modifyId = null		
		this.isLoading = false
	}

	private openCreateModal(doc: any) {
		this.modifyId = null
		this.parentDocument = doc;
	}

	private closeCreateModal() {
		this.parentDocument = null
	}

	private async saveNewChildDocument(params: any) {
		const body = {
			template: false,
			parent_id: params.document.template_id,
			title: params.name
		}

		this.createLoading = true
		const { data } = await this.multipleDocumentsService.createNewDocument(body)
		
		this.parentDocument = null
		this.createLoading = false		

		this.$router.push('/projects/' + this.projectId + '/flexible-document/' + data.data.template_id)
	}

}
